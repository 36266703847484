import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/plugins/animation.css';
import './assets/css/plugins/feature.css';
import './assets/css/plugins/magnify.min.css';
import './assets/css/plugins/slick.css';
import './assets/css/plugins/slick-theme.css';
import './assets/css/plugins/lightbox.css';
import './assets/css/style.css';
import { FaWhatsapp } from "react-icons/fa";



function App() {
  return (
    <div className='whatsapp-ccss'>
      <Home />
      <div className="enq-side-btn-icone ">

        <a href='https://wa.me/919358593003?text=I%20am%20Interested%20in%20Gaming%20Software.' target='_blank'  >
          <img style={{ width: "40px" }} src="./whatsapp3.webp.jpg" />
        </a>

      </div>
    </div>
  );
}

export default App;
